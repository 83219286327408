import { DresonRuleResolveEvent, HistoricDresonRuleResolveEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";
import { replaceTrackingContext } from "../../tracking/TrackingAPI";
import { core } from "@otto-ec/global-resources/core";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";
import { SfidWrapper } from "../../head/eventHistory/SfidWrapper";

export default class FilterResolveDresonRule {
  private static readonly URI: string = "/grasshopper/filter/resolve/dreson";

  /**
 *
 *
 *
 *
 */
  public static resolveFormData(data: DresonRuleResolveEvent | HistoricDresonRuleResolveEvent): void {
    const mustResolve = !data.historic && (<DresonRuleResolveEvent>data).formData;
    const { page, query, tsLink, shouldScroll } = mustResolve
      ? FilterResolveDresonRule.parseEventData(data as DresonRuleResolveEvent)
      : FilterResolveDresonRule.parseHistoricEventData(data as HistoricDresonRuleResolveEvent);

    if (!mustResolve && FilterResolveDresonRule.getCurrentRule() === (<HistoricDresonRuleResolveEvent>data).rule) {
      eventQBus.emit("ftfind.dresonRule.resolved", {
        ssid: SfidWrapper.ssid(),
        ...data,
      });
      return;
    }

    const tracking = core.serialize({
      ts_Assert: "none",
      ...tsLink,
    }) as string;

    fetch(FilterResolveDresonRule.URI + `?${query}`, {
      headers: {
        "X-Find-Trackable": tracking,
      },
    })
      .then(FilterResolveDresonRule.extractResponse)
      .then(FilterResolveDresonRule.replaceSFID)
      .then(FilterResolveDresonRule.updateMetaData)
      .then(FilterResolveDresonRule.refreshTrackingContext)
      .then(FilterResolveDresonRule.checkZeroHits)
      .then((avContentData: any) => {
        eventQBus.emit("ftfind.dresonRule.resolved", {
          historic: data.historic,
          mergeOnPi: true,
          shouldScroll: shouldScroll,
          page,
          ssid: SfidWrapper.ssid(),
          ...avContentData,
        });
      })
      .catch(FilterResolveDresonRule.handleError);
  }

  static checkZeroHits(avContentData: any): any {
    const element: HTMLElement | null = FilterResolveDresonRule.invalidFilterIds();
    if (element) {
      const { invalidFilterIds = "" } = element.dataset;
      eventQBus.emit("ftfind.advice.showZeroHits", {
        invalidFilterIds: invalidFilterIds.split(","),
      });
      throw new Error("Found no hits.");
    }
    return avContentData;
  }

  private static getCurrentRule() {
    const ruleDiv: HTMLElement | null = FilterResolveDresonRule.avContent();
    return ruleDiv ? ruleDiv.dataset.rule : undefined;
  }

  private static parseHistoricEventData(data: HistoricDresonRuleResolveEvent) {
    const { rule, page, tsLink, shouldScroll } = data;
    const query = (URLSearchParams && new URLSearchParams({ rule }).toString()) || core.serialize({ rule });
    return { page, query, tsLink, shouldScroll };
  }

  private static parseEventData(data: DresonRuleResolveEvent) {
    const { formData } = data;
    const sortOrder = <string>formData.get("sortiertnach");
    const shouldScroll = FilterResolveDresonRule.shouldScroll();
    /*         */
    const query = new URLSearchParams(formData).toString();
    return { page: { sortiertnach: sortOrder }, query, tsLink: {}, shouldScroll };
  }

  private static extractResponse(xhr: Response) {
    if (xhr.status !== 200 && xhr.status !== 404) {
      throw Error(`Failed to resolve rule: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  private static replaceSFID(response: string) {
    const sfidWrapper: HTMLElement | null = document.getElementById("reptile-sfid-wrapper");
    if (sfidWrapper && window.o_tracking) {
      sfidWrapper.innerHTML = response;
    }
  }

  private static updateMetaData(): { title?: string } {
    const element: HTMLAnchorElement | null = FilterResolveDresonRule.menuProperties();
    if (element) {
      const title = element.title;
      const canonical = element.href;
      const browserTitle = element.innerHTML;
      const { description, robots } = element.dataset;

      document.title = browserTitle;

      FilterResolveDresonRule.documentLink("canonical", canonical);
      FilterResolveDresonRule.documentMeta("description", description);
      FilterResolveDresonRule.documentMeta("robots", robots);
      FilterResolveDresonRule.documentMetaProperty("og:title", title);

      return { title: browserTitle };
    }
    return {};
  }

  private static refreshTrackingContext(): object {
    const element: HTMLElement | null = FilterResolveDresonRule.avContent();
    if (element) {
      const data = element.dataset;
      replaceTrackingContext("reptile-sfid-wrapper", data.redirect || "");
      return data;
    }
    return {};
  }

  private static invalidFilterIds() {
    return document.getElementById("find_invalidFilterIds");
  }

  private static avContent(): HTMLElement | null {
    return document.getElementById("avContent");
  }

  private static menuProperties(): HTMLAnchorElement | null {
    return document.getElementById("find_menuProperties") as HTMLAnchorElement;
  }

  private static documentLink(rel: string, value?: string): void {
    const elem: HTMLLinkElement | null = document.querySelector(`link[rel="${rel}"]`);
    if (elem && value) {
      elem.href = value;
    }
  }

  private static documentMeta(name: string, value?: string): void {
    const elem: HTMLMetaElement | null = document.querySelector(`meta[name="${name}"]`);
    if (elem && value) {
      elem.content = value;
    }
  }

  private static documentMetaProperty(property: string, value?: string): void {
    const elem: HTMLMetaElement | null = document.querySelector(`meta[property="${property}"]`);
    if (elem && value) {
      elem.content = value;
    }
  }

  private static handleError(e: Error): void {
    /*                                                                                                */
    eventQBus.emit("ftfind.dresonRule.resolveAborted");
  }

  private static shouldScroll() {
    return breakpoint?.isSmall() || breakpoint?.isMedium();
  }
}
