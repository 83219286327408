const {
  core,
  core: {
    bind,
    clone,
    coerce,
    convertStringToFunction,
    deserialize,
    extend,
    isEmptyObject,
    isPlainObject,
    removeFromArray,
    serialize,
    serializeForm,
    toArray
  }
} = window.o_util;

export { bind, clone, coerce, convertStringToFunction, core, deserialize, extend, isEmptyObject, isPlainObject, removeFromArray, serialize, serializeForm, toArray };
/*                             */
