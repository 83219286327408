import { LocalNavigationLoadEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";
import { getMergeParameterQuery } from "../../tracking/TrackingAPI";

export default class LoadLocalNavigation {
  private static readonly SES: string = "Suchergebnisseite";

  static onLoadLocalNavigation(data: LocalNavigationLoadEvent): void {
    const findLocalNavigationNode: HTMLElement | null =
      document.getElementById("reptile-local-navigation") || document.getElementById("find_localNavigation");
    if (!findLocalNavigationNode) {
      return;
    }

    /*                                                                           */
    const { rule } = findLocalNavigationNode.dataset;
    if (rule === data.rule || LoadLocalNavigation.currentPageType() === LoadLocalNavigation.SES) {
      return;
    }

    fetch(LoadLocalNavigation.uri(data.rule))
      .then(LoadLocalNavigation.extractResponseText)
      .then((html: string) => LoadLocalNavigation.replaceNodeContents(findLocalNavigationNode, data.rule, html))
      .then(LoadLocalNavigation.emitLocalNavigationLoaded)
      .catch((e: Error) => {
        console.warn(`Filter reload AJAX GET against ${LoadLocalNavigation.uri(data.rule)} failed: ${e.message}`);
      });
  }

  private static extractResponseText(xhr: Response) {
    if (xhr.status !== 200) {
      throw Error(`Failed to retrieve navigation: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  private static replaceNodeContents(node: HTMLElement, rule: string, html: string) {
    /*                                 */
    const range = document.createRange();
    range.selectNodeContents(node);

    /*                                                               */
    const fragment = range.createContextualFragment(html);

    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                     */
    node.dataset.rule = rule;

    /*                                                                     */
    window.preload_polyfill_invoke(node);
  }

  private static emitLocalNavigationLoaded() {
    eventQBus.emit("ftfind.localNavigation.loaded");
  }

  private static uri(dataRule: string): string {
    const ruleString = `${encodeURIComponent(dataRule)}`;
    return `/nav-sulu/local-navigation/${ruleString}?${getMergeParameterQuery()}`;
  }

  private static currentPageType() {
    const elem = document.getElementById("avContent");
    return elem && elem.dataset.pt;
  }
}
